import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import { HttpClient } from '@angular/common/http';
// import { ClientService } from './client.service';

@Component({
	selector: 'appointments-solution-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	currentUrl = '';

	routeTitles = {
		'/act-de-identitate': 'Eliberare act de identitate',
		'/casatorie': 'Căsătorie',
		'/transcriere': 'Transcriere',
		'/documente': 'Descărcare documente',
		'/suport-online': 'Suport online',
		'/plati-online': 'Plăți Online',
		'/plati-online/success': 'Plăți Online - Succes',
		'/plati-online/fail': 'Plăți Online - Eșuată',
	};

	routeIcons = {
		'/act-de-identitate': 'subtitles',
		'/casatorie': 'toll',
		'/transcriere': 'translate',
		'/documente': 'get_app',
		'/suport-online': 'support',
	};

	get isBreadcrumbRoute() {
		return this.currentUrl !== '/programari-online' && this.currentUrl !== '/';
	}

	constructor(
		private router: Router // private http: HttpClient,
	) // private clientService: ClientService
	{
		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.currentUrl = val.url;
				setTimeout(() => window.scroll(0, 0), 0);
			}
		});

		// this.clientService.isAllowedIp().then((isAllowedIp) => {
		//   if (!isAllowedIp) {
		//     console.log('IP DENIED. Redirecting...');
		//     location.href = 'http://deps4.ro';
		//   } else {
		//     console.log('IP ALLOWED!');
		//   }
		// })
	}

	navigateToPs4() {
		window.location.href = 'http://ps4.ro/';
	}
}
